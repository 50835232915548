<template lang="pug">
	.card-basic.offer-statistics(v-if='item')
		p.b1.mb-4 {{ $t('plan.allTime') }}

		.d-flex.flex-column-reverse.flex-md-column
			.d-flex.flex-column.flex-md-row.justify-content-between.mb-md-4
				.d-flex.flex-column.offer-statistics__item.primary
					span.b3.mb-1.text-center {{ $t('project.analytics.clicks') }}
					h3.text-center {{ item.views }}
				.d-flex.flex-column.offer-statistics__item.success
					span.b3.mb-1.text-center {{ $t('project.analytics.registers') }}
					h3.text-center {{ item.merchants }}
				.d-flex.flex-column.offer-statistics__item.violet
					span.b3.mb-1.text-center {{ $t('menu.projects') }}
					h3.text-center {{ item.projects }}

			.d-flex.flex-wrap.flex-row.justify-content-between.mb-4.mb-md-0
				.d-flex.flex-column.offer-statistics__element
					span.b3.mb-1.text-center.text-grey(v-html="`${$t('project.analytics.profit')} ${isWindows ? `<img class='' src='/assets/img/icons/russian-flag.png' alt='Russian Flag' width='15' height='auto'>` : '🇷🇺'}`")
					h2.text-center(v-html="item.cashflowOrganization.priceWithCurrency()")
				.d-flex.flex-column.offer-statistics__element
					span.b3.mb-1.text-center.text-grey(v-html="`${$t('project.analytics.yourIncome')} ${isWindows ? `<img class='' src='/assets/img/icons/russian-flag.png' alt='Russian Flag' width='15' height='auto'>` : '🇷🇺'}`")
					h2.text-center(v-html="item.incomeOrganization.priceWithCurrency()")
				.d-flex.flex-column.offer-statistics__element
					span.b3.mb-1.text-center.text-grey {{ `${$t('project.analytics.profit')} 🌎` }}
					h2.text-center(v-html="item.cashflowWorld2ru.priceWithCurrency()")
				.d-flex.flex-column.offer-statistics__element
					span.b3.mb-1.text-center.text-grey {{ `${$t('project.analytics.yourIncome')} 🌎` }}
					h2.text-center(v-html="item.incomeWorld2ru.priceWithCurrency()")
</template>

<script>
export default {
    name: 'OfferStatistics',
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.offer-statistics {
    &__item {
        padding: 10px 16px;
        width: calc(33.33% - 6.5px);
        border-radius: 15px;

        @include respond-below(sm) {
            width: 100%;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &.success {
            background-color: var(--brand-bg-success);

            span {
                color: var(--brand-success);
            }
        }

        &.primary {
            background-color: var(--brand-bg-primary);

            span {
                color: var(--brand-primary);
            }
        }

        &.violet {
            background-color: var(--brand-bg-violet);

            span {
                color: var(--brand-light-gradient-secondary);
            }
        }
    }

    &__element {
        padding: 10px 16px;
        width: calc(25% - 7.5px);
        border-radius: 15px;
        background-color: var(--bg-back);

        @include respond-below(sm) {
            width: calc(50% - 5px);
            white-space: nowrap;

            &:first-child,
            &:nth-child(2) {
                margin-bottom: 15px;
            }
        }
    }
}
</style>
