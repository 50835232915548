<template lang="pug">
	.mb-3.card-basic.payment-link
		p.b1.mb-1.payment-link__title {{ title }}
		p.b3.mb-0.text-grey 🔗&nbsp;{{ subTitle }}

		.d-flex.justify-content-between.align-items-center.mt-3.payment-link__card-link(v-if="!isMobile")
			a.b1.text-break.text-primary.mr-md-3(:href='paymentLinkDefault' target='_blank') {{ paymentLinkDefault }}
			.d-flex(v-if="!isMobile")
				i.text-primary.fontello.fontello-icon-edit.cursor-pointer.ml-auto.mr-10.btn-icon(v-if="edit" @click="$router.push({name: 'project_settings', params: {id: item.id}, query: {plan: id}})")
				i.text-primary.fontello.fontello-icon-copy.cursor-pointer.btn-icon(@click="copy(paymentLinkDefault)")
				i.text-primary.fontello.btn-icon.cursor-pointer.fontello-icon-scan-barcode-1.ml-10(@click='openModalQRCode()')

			i.text-primary.fontello.fontello-icon-dots.mb-auto.payment-link__dots(v-if="isMobile" @click="onOpenPopover" id="link-additional")

			b-popover(v-if="isMobile" ref="popoverAdditional" target="link-additional" :custom-class="'popover-one'")
				i.my-1.fontello-btn.fontello-btn-primary.fontello-icon-copy(@click="copy(paymentLinkDefault), onClosePopover")
				i.my-1.fontello-btn.fontello-btn-primary.fontello-icon-edit(v-if="edit" @click="$router.push({name: 'project_settings', params: {id: item.id}, query: {plan: id}}), onClosePopover('popoverAdditional')")
				i.my-1.fontello-btn.fontello-btn-primary.fontello-icon-scan-barcode-1(@click="openModalQRCode()")

		.d-flex.justify-content-between(v-if="isMobile")
			a.payment-link__btn(:href='paymentLinkDefault' target='_blank')
				i.fontello-btn.fontello-btn-primary.fontello-icon-link-1

			.payment-link__btn(@click="openModalQRCode()")
				i.fontello-btn.fontello-btn-primary.fontello-icon-scan-barcode-1

		modal-qr-code(:projectImage='projectImage' :projectName='projectName' :qrPaymentPage="isPartners ? qrReferral : qrPaymentPage" :isPartners="isPartners")

</template>

<script>
import { mapGetters } from 'vuex';
import { bus } from '@/main.js';
import ModalGeneral from '@/components/Modals/ModalGeneral';
import ModalQRCode from '@/components/Modals/ModalQRCode';
import IconClose from '@/components/Common/IconClose';

export default {
    name: 'PaymentLink',
    props: {
        edit: {
            type: Boolean,
            default: true,
        },
        title: { type: String, default: bus.$t('project.link') },
        subTitle: { type: String, default: bus.$t('project.linkDescription') },
        link: { type: String, default: null },
        showCustomLink: { type: Boolean, default: false },
        projectImage: { type: String, default: null },
        projectName: { type: String, default: null },
        isPartners: { type: Boolean, default: false },
    },
    components: {
        ModalGeneral,
        IconClose,
        'modal-qr-code': ModalQRCode,
    },
    computed: {
        ...mapGetters({
            item: 'project/opened',
            qrPaymentPage: 'project/qrPaymentPage',
            qrReferral: 'users/qrReferral',
        }),
        paymentLink() {
            return this.item ? `${this.paymentLinkDomain}${this.item.hashIdCustom}` : '';
        },
        paymentLinkDefault() {
            if (this.link != null) return this.link;
            if (!this.item) return '';

            if (this.item.hashIdCustom != this.item.hashId) return this.paymentLink;

            return this.item ? `${this.paymentLinkDomain}${this.item.hashId}` : '';
        },
        paymentLinkDomain() {
            return `${window.location.protocol}//${window.location.host.replace('merchant.', '')}/`;
        },
    },
    created() {
        window.addEventListener('click', e => {
            if (this.overlay === true) {
                const target = e.target;

                if (!target.closest('.b-popover') && !target.closest('.payment-link__dots')) {
                    this.onClosePopover();
                }
            }
        });
    },
    methods: {
        async copy(text) {
            try {
                await this.$copyText(text);
                this.$notify('success', `😊 &nbsp;&nbsp;&nbsp;${this.$t('success.copyLink')}`);
            } catch (e) {
                console.log(e);
            }
        },
        onOpenPopover() {
            if (this.$refs.popoverAdditional) this.$refs.popoverAdditional.$emit('open');
            document.body.classList.add('overlay');
            this.overlay = true;
        },
        onClosePopover() {
            if (this.$refs.popoverAdditional) this.$refs.popoverAdditional.$emit('close');
            document.body.classList.remove('overlay');
            this.overlay = false;
        },
        openModalQRCode() {
            this.$bvModal.show(`show-qr-code`);
            this.onClosePopover();
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.payment-link {
    @include respond-below(sm) {
        position: relative;
        padding: 14px 17px 15px;
    }

    &__title {
        @include respond-below(sm) {
            margin-bottom: 5.5px !important;
        }
    }

    &__card-link {
        min-height: 69px;
        padding: 10px 20px;
        border-radius: var(--border-radius-rounded);
        background-color: var(--grayscale-bg-dark-back);

        @include respond-below(sm) {
            padding: 16px;
        }
    }

    &__dots {
        @include respond-below(sm) {
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }

    &__btn {
        width: calc(50% - 5px);
        min-height: 44px;
        margin-top: 15px;
        display: flex;

        i {
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;
            padding: 0;
            font-size: 18px;

            &::before {
                line-height: 44px;
            }
        }
    }
}
</style>
