<template lang="pug">
	b-dropdown.dropdown.w-100.dropdown-partner.animated(:id="id" menu-class="p-0 m-0" :class="{'z-index-1032 ' : overlay}")
		template(#button-content)
			span.b3 {{ $t('h1.projects') }}
			span.d-flex.dropdown-partner__length {{ list.length }}
		b-dropdown-item.dropdown-partner__item(v-for='(item, i) in list' :key='i' :href="`http:${item.linkHashId}`" target="_blank") {{ item.title ? item.title : $t('instruction.step0.neurobot.newNeurobot') }}
</template>

<script>
export default {
    name: 'DropdownPartner',
    props: {
        list: {
            default: () => [],
            type: Array,
        },
        id: {
            default: null,
            type: String,
        },
    },
    data: () => ({
        overlay: false,
        windowInnerHeight: null,
        scrollbarWidth: 0,
    }),
    created() {
        window.addEventListener('click', () => {
            if (this.overlay === true) {
                this.onClose();
            }
        }),
            (this.windowInnerHeight = window.innerHeight);
        window.addEventListener('resize', () => {
            this.windowInnerHeight = window.innerHeight;
        });
    },
    beforeMount() {
        setTimeout(() => {
            const scroll = window.innerWidth > document.body.clientWidth;
            this.$root.$on('bv::dropdown::show', bvEvent => {
                if (bvEvent.componentId === this.id) {
                    document.body.classList.add('overlay');
                    this.overlay = true;
                    if (!this.isMobile && scroll) {
                        document.querySelector('body').style.paddingRight = this.scrollbarWidth + 'px';
                    }
                }
            });
            this.$root.$on('bv::dropdown::hide', () => {
                document.body.classList.remove('overlay');
                this.overlay = false;
                document.querySelector('body').style.paddingRight = 0;
            }),
                this.getScrollbarWidth();
        }, 33);
    },
    methods: {
        onClose() {
            document.body.classList.remove('overlay');
            this.overlay = false;
            document.querySelector('body').style.paddingRight = 0;
        },
        getScrollbarWidth() {
            // Creating invisible container
            const outer = document.createElement('div');
            outer.style.visibility = 'hidden';
            outer.style.overflow = 'scroll'; // forcing scrollbar to appear
            outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
            document.body.appendChild(outer);

            // Creating inner element and placing it in the container
            const inner = document.createElement('div');
            outer.appendChild(inner);

            // Calculating difference between container's full width and the child width
            this.scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

            // Removing temporary elements from the DOM
            outer.parentNode.removeChild(outer);
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.dropdown-partner {
    height: 50px;

    &.z-index-1032 {
        z-index: 1032;
    }

    &:hover,
    &:active,
    &:focus {
        button {
            background-color: var(--foreground-color) !important;
            color: var(--main-text-color) !important;
            box-shadow: var(--btn-box-shadow) !important;
        }

        .dropdown-partner__length {
            background-color: var(--grayscale-dark-light-back);
        }
    }

    &__length {
        justify-content: center;
        min-width: 30px;
        padding: 3px 10px;
        font-size: 14px;
        line-height: 14px;
        color: var(--primary-text-color) !important;
        background-color: var(--grayscale-dark-white-back);
        border-radius: var(--border-radius-rounded) !important;
        transition: var(--animation-time-short);
        margin-left: 10px;
    }

    &__item {
        margin: 15px 0;
    }

    button {
        justify-content: flex-start;
        height: 50px;
        padding: 13px 15px !important;
        border: 1px solid transparent !important;
        background-color: var(--grayscale-bg-dark-back) !important;
        border-radius: var(--border-radius-rounded) !important;
        color: var(--main-text-color) !important;
        box-shadow: none !important;
    }

    .dropdown-toggle {
        border: 1px solid transparent !important;
        border-bottom: none !important;

        &::after {
            width: 18px;
            height: 18px;
            background: url('/assets/img/icons/arrow.svg');
            margin-left: auto !important;
            border: none !important;
        }
    }

    &.show .dropdown-toggle {
        position: relative;
        border-color: var(--border-line) !important;
        background-color: var(--foreground-color) !important;
        color: var(--main-text-color) !important;

        &::after {
            transform: rotate(180deg);
        }

        .dropdown-partner__length {
            background-color: var(--grayscale-bg-dark-back);
        }
    }

    &.show button {
        box-shadow: var(--box-shadow-hover) !important;
    }

    .dropdown-menu {
        top: 8px !important;
        right: 0 !important;
        padding: 0 15px !important;
        max-height: 360px !important;
        background-color: var(--foreground-color) !important;
        border-radius: var(--border-radius-rounded) !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        box-shadow: var(--box-shadow-plans) !important;
        border: 1px solid transparent !important;
        border-top: none !important;
        border-bottom: none !important;
    }

    &.show ul li:first-child:before {
        content: none !important;
    }

    .dropdown-item {
        display: flex;
        flex-direction: column;
        padding: 10px !important;
        font-size: 14px;
        line-height: 22px; /* 157.143% */
        color: var(--primary-text-color) !important;
        box-shadow: var(--user-project-shadow) !important;
        border-radius: var(--border-radius-rounded) !important;
        background-color: var(--foreground-color);

        &:hover,
        &:active {
            color: var(--primary-dark-text-color) !important;
        }
    }

    &.animated {
        .dropdown-menu {
            overflow: auto;
            display: block !important;
            max-height: 0 !important;
            //transition: max-height var(--animation-time-short) ease-in-out;

            &:not(.show) {
                padding: 0;
                border: none;
            }

            &.show {
                //transition: max-height var(--animation-time-short) ease-in-out;
                max-height: 180px !important;
                background-color: var(--foreground-color) !important;
                border: 1px solid var(--border-line) !important;
                border-top: none !important;
            }
        }
    }
}
</style>
