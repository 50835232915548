<template lang="pug">
	.offer
		div(v-if="offer && me")
			h2.mb-4  {{ $t('h1.offer') }}

			.d-flex.flex-column.flex-md-row.mb-4
				payment-link.mb-4.mb-md-0.mr-md-4.w-100(:title="$t('partner.linkTitle')" :link='me.link' :edit='false' :isPartners='true' :projectImage="image.contentUrl" :projectName="offer[0].username")

				.card-basic
					.disclaimer.no-border.offer__disclaimer
						p.b1.mb-1 {{ $t('h1.terms') }}
						p.b3.text-grey.mb-0 {{ $t('partner.inviteDesc') }}
						svg(v-if="!isMobile" xmlns="http://www.w3.org/2000/svg" width="107" height="62" viewBox="0 0 107 62" fill="none")
							circle(opacity="0.2" cx="53.5" cy="8.5" r="53" stroke="#52A7F9" stroke-linecap="round" stroke-dasharray="10 10")

			card-offer-statistics.mb-4(v-if='offer && offer[0]' :item='offer[0]')

			table-partner-merchants
		.loading(v-else)
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PaymentLink from '@/components/Common/PaymentLink';
import CardOfferStatistics from '@/components/Cards/OfferStatistics';
import TablePartnerMerchants from '@/components/Tables/PartnerMerchants';
import { bus } from '@/main.js';
import { CImage } from '@/models/image';

export default {
    components: {
        PaymentLink,
        'card-offer-statistics': CardOfferStatistics,
        TablePartnerMerchants,
    },
    metaInfo() {
        return { title: bus.$t('h1.offer') };
    },
    data: () => ({
        tab: 0,
    }),
    computed: {
        ...mapGetters({
            offer: 'users/offer',
        }),
        image() {
            return new CImage(this.offer[0].avatar);
        },
    },
    methods: {
        ...mapActions({
            getOffer: 'users/offer',
            getQrReferral: 'users/qrReferral',
        }),
    },
    mounted() {
        this.getOffer();
        this.getQrReferral().then(v => {
            this.$store.commit('users/setQrReferral', v);
        });
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.offer {
    margin-bottom: 120px;

    &__disclaimer {
        position: relative;
        border: none;
        color: var(--main-text-color);

        svg {
            position: absolute;
            top: -20px;
            right: -20px;
        }

        @include respond-below(sm) {
            width: 100%;
        }
    }
}
</style>
